<template>
  <!-- <div class="meeting"> -->
  <!-- <div class="container-zoom"> -->
  <ZoomFrame
    v-if="meetingId && nickname && password"
    :meetingId="meetingId"
    :nickname="nickname"
    :password="password"
  />
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import ZoomFrame from "../../components/zoomComponents/ZoomFrame.vue";
import axios from "axios";

export default {
  name: "Meeting",
  data() {
    return {
      meetingId: "",
      password: "",
      nickname: "",
      signature: "",
    };
  },
  components: {
    ZoomFrame,
  },
  methods: {
    getMeetingData() {
      axios
        .get(
          this.getApiUrl + "/online_meeting/getMeetingData/" + this.meetingId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          this.nickname = res.data.data.nickname
            ? res.data.data.nickname
            : "Guest";
          this.password = res.data.data.password;
          this.meetingId = res.data.data.meetingId;
          this.signature = res.data.data.signature;
        });
    },
  },
  mounted() {
    this.meetingId = this.$router.currentRoute.params.meetingId;
    this.getMeetingData();
  },
};
</script>

<style scoped>
.container-zoom {
  width: 70%;
  height: 100%;
}
</style>
