<template>
  <div class="iframe-container">
    <meta charset="utf-8" />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://dmogdx0jrul3u.cloudfront.net/1.3.7/css/bootstrap.css"
    />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://dmogdx0jrul3u.cloudfront.net/1.3.7/css/react-select.css"
    />

    <meta name="format-detection" content="telephone=no" />
  </div>
</template>
<script>
// import Timer from "../Timer";
// import Auth from "../../helpers/auth";
import ACL from "../../acl";
import axios from "axios";
import jQuery from "jquery";
window.jQuery = window.$ = jQuery;
// import { ZoomMtg } from "@zoomus/websdk";
// if (window.location.pathname.split("/")[1] == "meeting") {
import { ZoomMtg } from "@zoomus/websdk";
// console.log(ZoomMtg);
// console.log("checkSystemRequirements");
// console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.9.5/lib", "/av");
// ZoomMtg.setZoomJSLib("https://jssdk.zoomus.cn/2.7.0/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");
// CDN version default
// const r = require('')
// ZoomMtg.setZoomJSLib("https://erp.jwa.edu.sa/zoom-websdk/dist/lib", "/av");
// ZoomMtg.setZoomJSLib("https://source.zoom.us/2.7.0/lib", "/av");

// ZoomMtg.preLoadWasm();

// ZoomMtg.prepareJssdk();
// }

// var API_KEY = "zPhLf2nTRCGqUl5WZnkqwg";

// var API_SECRET = "S4Law99CCUJiA0k1PT00jj304idwJ48abRNJ";
// var API_KEY = "0M4B4xySTq2RptQeOgdoKA";
// var API_SECRET = "9FZtoFAYxoyNaHtRy8F0EkRzozD7iOHNaU4O";

export default {
  name: "ZoomFrame",
  // components: { Timer },
  created() {},
  data() {
    return {
      sdkKey: "",
      sdkSecret: "",
      leaveUrl: "https://google.com",
      // meetingNumber: "76832405863",
      // passWord: "r47Lgp",
      role: 0,
      userEmail: "email@email.com",
      // userName: "Vue.js",

      // registrantToken: "",
    };
  },
  props: ["meetingId", "nickname", "password"],

  methods: {
    initZoomMeeting() {
      jQuery("#zmmtg-root").css("display", "inline-block");
      jQuery("#main-video").css("transform", "scaleX(-1)");
      jQuery("#media-preview-camera-on").css("transform", "scaleX(-1)");
      jQuery("#speak-view-video").css("transform", "scaleX(-1)");
      jQuery("#suspension-video").css("transform", "scaleX(-1)");
      // let role = 0;
      // if (ACL.Role("Teacher")) {
      //   role = 1;
      // }
      // this.meetConfig = {
      //   apiKey: this.apiKey,
      //   apiSecret: this.apiSecret,
      //   meetingNumber: this.meetingId,
      //   userName: this.nickname,
      //   passWord: this.password,
      //   leaveUrl: window.location + "/thank-you",
      //   role: role,
      // };
      // console.log("meetConfig", this.meetConfig);
      // var _this = this;
      // Generate Signature function
      // this.signature = ZoomMtg.generateSignature({
      //   meetingNumber: this.meetConfig.meetingNumber,
      //   apiKey: this.meetConfig.apiKey,
      //   apiSecret: this.meetConfig.apiSecret,
      //   role: this.meetConfig.role,
      //   success: function (res) {
      //     // eslint-disable-next-line
      //     console.log("success signature: " + res.result);
      //   },
      // });
      ZoomMtg.showJoinAudioFunction({
        show: true,
      });
      this.generateSignature();
      // _this.initZoomObject();
    },
    initZoomObject() {
      // join function
      ZoomMtg.init({
        leaveUrl: window.location,
        isSupportAV: true,
        success: () => {
          ZoomMtg.join({
            meetingNumber: this.meetConfig.meetingNumber,
            userName: this.meetConfig.userName,
            signature: this.signature,
            apiKey: this.meetConfig.apiKey,
            userEmail: "email@gmail.com",
            passWord: this.meetConfig.passWord,
            success: function (res) {
              // eslint-disable-next-line
              console.log(res + "join meeting success");
            },
            error: function (res) {
              // eslint-disable-next-line
              console.log(res);
            },
          });
        },
        error: function (res) {
          // eslint-disable-next-line
          console.log(res);
        },
      });
    },
    endZoomMeeting() {
      ZoomMtg.endMeeting();
    },
    getZoomConfigration() {
      axios
        .get(
          this.getApiUrl +
            "/online_meeting/get_configuration_class/" +
            this.meetingId,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data != null) {
              this.sdkKey = response.data.data.api_key;
              this.sdkSecret = response.data.data.api_secret_key;
              this.generateSignature();
              // this.startMeeting();
            }
          }
        });
    },
    // getSignature() {
    //   axios
    //     .post(this.signatureEndpoint, {
    //       meetingNumber: this.meetConfig.meetingNumber,
    //       role: this.meetConfig.role,
    //     })
    //     .then((res) => {
    //       console.log(res.data.signature);
    //       this.startMeeting(res.data.signature);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    generateSignature() {
      var _this = this;
      var signature = ZoomMtg.generateSDKSignature({
        meetingNumber: this.meetingId,
        sdkKey: this.sdkKey,
        sdkSecret: this.sdkSecret,
        role: ACL.Role("Teacher") ? 1 : 0,
        success: function (res) {
          console.log("res.result", res.result);
          _this.startMeeting(res.result);
        },
      });
      console.log("signature:::::", signature);
    },
    startMeeting(signature) {
      // jQuery("#zmmtg-root").css("display", "inline-block");

      console.log("this.meetConfig.meetingNumber", this.meetingId);
      // console.log("this.signature", signature);
      document.getElementById("zmmtg-root").style.display = "block";
      ZoomMtg.showJoinAudioFunction({
        show: true,
      });
      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        success: (success) => {
          console.log(success);
          jQuery("#media-preview-camera-on").css("transform", "scaleX(-1)");
          ZoomMtg.join({
            meetingNumber: this.meetingId,
            userName: this.nickname,
            signature: signature,
            sdkKey: this.sdkKey,
            userEmail: "email@gmail.com",
            passWord: this.password,
            // tk: this.registrantToken,
            success: (success) => {
              console.log("success joined", success);
              setTimeout(() => {
                window
                  .jQuery("#main-video-self")
                  .css("transform", "scaleX(-1)");
                window.jQuery("#main-video").css("transform", "scaleX(-1)");
                window
                  .jQuery("#speak-view-video")
                  .css("transform", "scaleX(-1)");
                window
                  .jQuery("#suspension-video")
                  .css("transform", "scaleX(-1)");
              }, 3000);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
  mounted() {
    jQuery("#app").html(
      "<div class='meeting'><div class='container-zoom'></div></div>"
    );
    this.getZoomConfigration();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#zmmtg-root {
  display: inline-flex !important;
  /* position: unset !important; */
  width: 50% !important;
  top: 11% !important;
  left: 25% !important;
}
</style>
